<template>
  <div class="productService">
    <div>
      <homeComAbout :src="bgImg"></homeComAbout>
    </div>
    <!-- <div class="service">
      <div class="background-text">
        <div class="text-item">
          <span>P</span>
        </div>
        <div class="text-item">
          <span>R</span>
        </div>
        <div class="text-item">
          <span>O</span>
        </div>
        <div class="text-item">
          <span>C</span>
        </div>
        <div class="text-item">
          <span>E</span>
        </div>
        <div class="text-item">
          <span>S</span>
        </div>
        <div class="text-item">
          <span>S</span>
        </div>
      </div>
      <a-col>
        <div class="textCenter">
          <div class="homeTitle">
            集运流程
          </div>
          <div class="title-span">专业流程教您简单玩转集运</div>
        </div>
        <div class="container">
          <div>
            <div class="iconBox">
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT">
                      <div class="container">
                        <div class="iconImg">
                          <img class="img" src="@/assets/smartcat/01Icon.png" alt="">
                        </div>
                        <div class="text">
                          <p class="span">1、物品到仓</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT" style="background-color: #fff;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT">
                      <div class="container">
                        <div class="iconImg">
                          <img class="img" src="@/assets/smartcat/04Icon.png" alt="">
                        </div>
                        <div class="text">
                          <p class="span">4、包裹上架</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT">
                      <div class="container">
                        <div class="iconImg">
                          <img class="img" src="@/assets/smartcat/05Icon.png" alt="">
                        </div>
                        <div class="text">
                          <p class="span">5、包裹合并</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT" style="background-color: #fff;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT">
                      <div class="container">
                        <div class="iconImg">
                          <img class="img" src="@/assets/smartcat/08Icon.png" alt="">
                        </div>
                        <div class="text">
                          <p class="span">8、国际运输</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT">
                      <div class="container">
                        <div class="iconImg">
                          <img class="img" src="@/assets/smartcat/09Icon.png" alt="">
                        </div>
                        <div class="text">
                          <p class="span">9、C端收货</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="iconBox">
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT">
                      <div class="container">
                        <div class="iconImg">
                          <img class="img" src="@/assets/smartcat/02Icon.png" alt="">
                        </div>
                        <div class="text">
                          <p class="span">2、仓库收货</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT">
                      <div class="container">
                        <div class="iconImg">
                          <img class="img" src="@/assets/smartcat/03Icon.png" alt="">
                        </div>
                        <div class="text">
                          <p class="span">3、质检入库</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT" style="background-color: #fff;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT">
                      <div class="container">
                        <div class="iconImg">
                          <img class="img" src="@/assets/smartcat/06Icon.png" alt="">
                        </div>
                        <div class="text">
                          <p class="span">6、打包发货</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT">
                      <div class="container">
                        <div class="iconImg">
                          <img class="img" src="@/assets/smartcat/07Icon.png" alt="">
                        </div>
                        <div class="text">
                          <p class="span">7、申报出口</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Icon">
                <div class="IconF">
                  <div class="IconS">
                    <div class="IconT" style="background-color: #fff;">
                      <a id="lishi" onclick="skipCase(2);" class="IconUrl">10</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </div> -->
    <div class="solution">
      <div class="containerTop">
        <div class="container">
          <div class="container-left">
            <div class="head">
              <span class="homeTitle">面向集运用户的移动解决方案</span>
              <!-- <div class="secTitle">企业版小程序/个人版H5</div> -->
            </div>
            <div class="example">
              <div class="example-left">
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">一键复制仓库地址</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">包裹预报</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">邮寄类目风险提示</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">线路推荐 / 运费估算</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">包裹认领</span>
                  </div>
                </div>
              </div>
              <div class="example-right">
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">物流跟踪</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">优选评价</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">余额管理</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">一键复制仓库地址</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="more">
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                    <span class="circle"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-right">
            <img class="phoneImg" src="@/assets/smartcat/phone.png" alt="">
          </div>
        </div>
      </div>
      <div class="containerBot">
        <div class="container">
          <div class="container-left">
            <div class="head">
              <div class="secTitle">企业版小程序特有功能</div>
            </div>
            <div class="example">
              <div class="example-left">
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">充值体系</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">集运拼团</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">签到活动</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">跳转公众号活动</span>
                  </div>
                </div>
              </div>
              <div class="example-right">
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">UI个性化定制</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">功能个性化开发</span>
                  </div>
                </div>
                <div class="example-item">
                  <div class="pic">
                    <img class="img" src="@/assets/smartcat/tick.png" alt="">
                  </div>
                  <div class="text">
                    <span class="text-span">活动个性化开发</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="facePartner">
      <div class="services">
        <div class="background-text">
          <div class="text-item">
            <span>S</span>
          </div>
          <div class="text-item">
            <span>Y</span>
          </div>
          <div class="text-item">
            <span>S</span>
          </div>
          <div class="text-item">
            <span>T</span>
          </div>
          <div class="text-item">
            <span>E</span>
          </div>
          <div class="text-item">
            <span>M</span>
          </div>
        </div>
        <div class="textCenter">
          <div class="homeTitle">
            面向商户的集运管理系统
          </div>
          <div class="title-span">全场景全链路的SaaS产品，助力集运服务升级</div>
        </div>
        <div class="topBox">
          <img class="img" src="@/assets/smartcat/billboard.png" alt="">
        </div>
        <div class="row">
          <img class="rowImg" src="@/assets/smartcat/row.png" alt="">
        </div>
        <div class="fotBox">
          <div class="InBox">
            <div class="inUl">
              <div class="card-item">
                <div class="pic">
                  <img class="img" src="@/assets/smartcat/1Icon.png" alt="">
                </div>
                <div class="text">
                  <div class="title">数据看板</div>
                  <div class="written">全面把握包裹、订单、客户等经营数据</div>
                </div>
              </div>
              <div class="card-item">
                <div class="pic">
                  <img class="img" src="@/assets/smartcat/2Icon.png" alt="">
                </div>
                <div class="text">
                  <div class="title">基础配置</div>
                  <div class="written">支持自定义店铺名称，配置渠道及售价等</div>
                </div>
              </div>
            </div>
          </div>
          <div class="InBox">
            <div class="inUl">
              <div class="card-item">
                <div class="pic">
                  <img class="img" src="@/assets/smartcat/3Icon.png" alt="">
                </div>
                <div class="text">
                  <div class="title">预报包裹</div>
                  <div class="written">及时了解客户预报包裹及包裹入库状态</div>
                </div>
              </div>
              <div class="card-item">
                <div class="pic">
                  <img class="img" src="@/assets/smartcat/4Icon.png" alt="">
                </div>
                <div class="text">
                  <div class="title">订单管理</div>
                  <div class="written">及时了解下单客户、订单明细、签收状态</div>
                </div>
              </div>
            </div>
          </div>
          <div class="InBox">
            <div class="inUl">
              <div class="card-item">
                <div class="pic">
                  <img class="img" src="@/assets/smartcat/5Icon.png" alt="">
                </div>
                <div class="text">
                  <div class="title">财务管理</div>
                  <div class="written">商家经营流水、利润、对帐记录清晰可见</div>
                </div>
              </div>
              <div class="card-item">
                <div class="pic">
                  <img class="img" src="@/assets/smartcat/6Icon.png" alt="">
                </div>
                <div class="text">
                  <div class="title">营销工具</div>
                  <div class="written">支持优惠券、拼团、分销等多种营销方式</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="freeService">
      <div class="services">
        <div class="background-text">
          <div class="text-item">
            <span>S</span>
          </div>
          <div class="text-item">
            <span>O</span>
          </div>
          <div class="text-item">
            <span>L</span>
          </div>
          <div class="text-item">
            <span>U</span>
          </div>
          <div class="text-item">
            <span>T</span>
          </div>
          <div class="text-item">
            <span>I</span>
          </div>
          <div class="text-item">
            <span>O</span>
          </div>
          <div class="text-item">
            <span>N</span>
          </div>
          <div class="text-item">
            <span>S</span>
          </div>
        </div>
        <div class="textCenter">
          <div class="homeTitle">
            仓储和物流解决方案
          </div>
          <div class="title-span">200+国家线路，20+国家高时效自营专线，全品类可寄 ，开通系统即可经营</div>
        </div>
        <div class="customersType">
          <div class="rudiment">
            <div class="top">
              <img class="customersTypeIcon" src="@/assets/smartcat/rudiment.png" alt="">
              <div class="title">集运创业入门者</div>
            </div>
            <div class="serviceType">
              <div class="left">
                <img class="serviceIcon" src="@/assets/smartcat/storeService.png" alt="">
                <div class="serviceTitle">仓库服务</div>
              </div>
              <div class="right">
                <div class="items">
                  <div class="item">
                    <div class="itemTitle">180天代保管</div>
                    <div class="itemDetail">
                      自包裹入库起180天内免费代为<br>保管，且包裹上架至库位上。
                    </div>
                  </div>
                  <div class="item">
                    <div class="itemTitle">入库质检</div>
                    <div class="itemDetail">
                      人为检查商品质量，并对所有商<br>品进行违禁品排查。
                    </div>
                  </div>
                  <div class="item">
                    <div class="itemTitle">入库拍照</div>
                    <div class="itemDetail">
                      在质检后对包裹拍照并称重，照<br>片能够观察到物品并判断其数量<br>及外观特征。
                    </div>
                  </div>
                  <div class="item">
                    <div class="itemTitle">打包服务</div>
                    <div class="itemDetail">
                      根据订单备注或者物品属性进行<br>打包，保障物品完好，压缩包裹<br>体积重量。
                    </div>
                  </div>
                </div>
              <div class="hr"></div>
              </div>
            </div>
            <div class="serviceType" style="margin-top:40px">
              <div class="left">
                <img class="serviceIcon" src="@/assets/smartcat/channelService.png" alt="">
                <div class="serviceTitle">渠道服务</div>
              </div>
              <div class="right">
                <div class="items" style="margin-top:-20px">
                  <div class="item" style="">
                    <div class="itemTitle">普货专线</div>
                    <div class="itemDetail">
                      可寄服饰，碗盆，床上用品，手<br>
                      机壳支架等。
                    </div>
                  </div>
                  <div class="item">
                    <div class="itemTitle">带电专线</div>
                    <div class="itemDetail">
                      可寄内置电，弱磁的货物或能产<br>
                      生电力的商品。
                    </div>
                  </div>
                  <div class="item">
                    <div class="itemTitle">经济特货</div>
                    <div class="itemDetail">
                      价格较低，可寄食品，粉末液<br>
                      体，药品等。
                    </div>
                  </div>
                  <div class="item" style="width:220px">
                    <div class="itemTitle">标准特货</div>
                    <div class="itemDetail">
                      时效较快，可寄食品，粉末液<br>
                      体，药品等。
                    </div>
                  </div>
                </div>
                <div class="hr"></div>
              </div>
            </div>
          </div>
          <div class="senior">
            <div class="top">
              <img class="customersTypeIcon" src="@/assets/smartcat/senior.png" alt="">
              <div class="title">集运资深从事者</div>
            </div>
            <div class="containerDetail">
              <div class="leftImg">
                <img class="img" src="@/assets/smartcat/storeBg.png" alt="">
              </div>
              <div class="rightArticle">
                <div class="articleTitle">
                  仓库管理
                </div>
                <div class="articleDetail">
                  包裹入库：扫描包裹快递条码进行包裹入库，已
                  预报包裹，系统自动识别用户ID；<br>
                  异常件入库：未预报的包裹入库后，客人可以在
                  用户端进行包裹认领；<br>
                  合并打包：包裹合并打包，设置订单物品的重
                  量、体积、打包照片及增值服务费用；<br>
                  订单发货：发货单管理，将订单添加至发货单。
                </div>
                <div class="articleTitle">
                  物流管理
                </div>
                <div class="articleDetail">
                  渠道管理：自定义添加渠道，功能齐全，配置灵活；<br>
                  渠道价格管理：支持首重续重、阶梯定价、阶梯首重续重等多种定价方式；<br>
                  国家分区管理：支持国家分区管理，细化各区域渠道定价；<br>
                  渠道增值服务：支持多种类型、定价方式的渠道增值服务，满足个性化配置需求。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="commercial-college">
      <div class="about-left">
        <div class="pic">
          <div class="pic-left">
            <div class="logistics">
              <div class="topTitle">
                <div class="title">仓储物流</div>
              </div>
              <div class="container">
                <div class="p">集运仓储服务培训</div>
                <div class="p">国际物流服务培训</div>
              </div>
              <div class="pic">
                <img class="img" src="@/assets/smartcat/001logistics.png" alt="">
              </div>
            </div>
          </div>
          <div class="pic-right">
            <div class="top">
              <div class="topTitle">
                <div class="title">集运系统服务</div>
              </div>
              <div class="container">
                <div class="p">基础建站培训</div>
                <div class="p">营销功能培训</div>
              </div>
              <div class="pic">
                <img class="img" src="@/assets/smartcat/002logistics.png" alt="">
              </div>
            </div>
            <div class="bot">
              <div class="topTitle">
                <div class="title">营销运营</div>
              </div>
              <div class="container">
                <div class="p">私域运营培训</div>
                <div class="p">定价及促销培训</div>
                <div class="p">新媒体营销推广培训</div>
                <div class="p">代理开发培训培训</div>
              </div>
              <div class="pic">
                <img class="img" src="@/assets/smartcat/003logistics.png" alt="">
              </div>
            </div>
          </div>
          <img class="rowImg" src="@/assets/smartcat/Shapex.png" alt="">
          <img class="rowImg2" src="@/assets/smartcat/Shapex.png" alt="">
        </div>

      </div>
      <div class="about-right">
        <div class="head">
          <span class="homeTitle">
            集运商学院培训体系
          </span>
        </div>
        <div class="container">
          <p class="item">SmartCat集运商学院是SmartCat推出的在线学习平台，致力于帮</p> <br>
          <p class="item">助数万位全球创业者轻松做集运，提供集运系统SAAS指导、实战</p> <br>
          <p class="item">运营、私域营销思路、集运专业知识，行业商业机会，优秀实操</p> <br>
          <p class="item">案例等，专业讲师在线指导，帮助商家学习成长，快速进阶成为</p> <br>
          <p class="item">专才，培育未来集运板块的行业领袖。</p>
        </div>
      </div>
    </div>
    <div class="choiceService">
      <div class="services">
        <div class="background-text">
          <div class="text-item">
            <span>P</span>
          </div>
          <div class="text-item">
            <span>R</span>
          </div>
          <div class="text-item">
            <span>O</span>
          </div>
          <div class="text-item">
            <span>D</span>
          </div>
          <div class="text-item">
            <span>O</span>
          </div>
          <div class="text-item">
            <span>C</span>
          </div>
          <div class="text-item">
            <span>T</span>
          </div>
          <div class="text-item">
            <span>S</span>
          </div>
        </div>
        <div class="textCenter">
          <div class="homeTitle">
            选择适合您的产品与服务
          </div>
          <div class="title-span">6个可选版本，帮助全球创业者轻松做集运</div>
        </div>
        <div class="priceImg">
          <img class="price" :src="price" alt="">
        </div>
        <div class="botContact">
           <div class="all">
               <div class="titleTop">
                  申请试用
               </div>
               <img class="contactImg" src="@/assets/smartcat/weChat.jpg" alt="">
               <div class="titleBot">
                您可以扫描我们的二维码，咨询具体功能和报价，我们将为您提供7天免费产品体验。
               </div>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeComAbout from "../homeComponents/homeComAbout.vue"
export default {
  name: 'homeComIntroduce',
  components: {
    homeComAbout,
  },
  data () {
    return {
      bgImg: require('@/assets/smartcat/bannerProduct.png'),
      price:require('@/assets/smartcat/price.svg')
    }
  },
}
</script>

<style lang="scss" scoped="scoped">
.productService {


  .service {
    width: 1200px;
    margin: auto;
    position: relative;

    .background-text {
      margin: 120px auto;
      display: flex;
      justify-content: space-between;

      height: 60px;


      .text-item {
        height: 101px;
        font-size: 72px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 100.8px;
        color: #f5f5f5;
      }
    }


    .textCenter {
      position: absolute;
      left: 50%;
      bottom: 120%;
      transform: translate(-50%, -50%);
      text-align: center;

      .homeTitle {
        width: 146px;
        margin: auto;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 32px;
        color: rgba(0, 0, 0, 1);
        background: linear-gradient(to top, #fdd000 4px, white 50%, white 51%, white 100%);

      }

      .title-span {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
      }

    }

    .container {

      .iconBox {
        width: 1200px;
        display: flex;
        justify-content: center;
        margin-top: -20px;

        .Icon {
          height: 150px;
          width: 130px;
          margin-right: 10px;
          margin-left: 10px;

          .IconF {
            transform: rotate(120deg);
            -ms-transform: rotate(120deg);
            -moz-transform: rotate(120deg);
            -webkit-transform: rotate(120deg);

            .IconS {
              transform: rotate(-60deg);
              -ms-transform: rotate(-60deg);
              -moz-transform: rotate(-60deg);
              -webkit-transform: rotate(-60deg);

              .IconT {
                background-color: #fdd000;
                transform: rotate(-60deg);
                -ms-transform: rotate(-60deg);
                -moz-transform: rotate(-60deg);
                -webkit-transform: rotate(-60deg);

                .container {
                  margin-top: 40px;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;

                  .iconImg {
                    .img {
                      width: 40px;
                      height: 40px;
                    }
                  }

                  .text {
                    margin-top: 10px;

                    .span {
                      font-size: 16px;
                      font-weight: 400;
                      letter-spacing: 0px;
                      line-height: 22.4px;
                      color: rgba(0, 0, 0, 1);
                    }
                  }
                }
              }
            }
          }
        }
      }

      .IconF,
      .IconS,
      .IconT {
        width: 130px;
        height: 150px;
        overflow: hidden;
        margin: 0 auto;
      }

    }


  }
}

.solution {
  width: 100%;
  margin-top: 150px;



  .containerTop {
    display: flex;
    background-color: #ffffff;
    padding-bottom: 50px;

    .container {
      width: 1200px;
      margin: auto;
      position: relative;
    }

    .container-left {

      .head {
        margin-top: 60px;

        .homeTitle {
          margin-top: 120px;
          font-size: 36px;
          font-weight: 700;
          letter-spacing: 0px;
          color: rgba(0, 0, 0, 1);
          background: linear-gradient(to top, #fdd000 4px, #f8f8f8 40%, #f8f8f8 51%, #f8f8f8 100%);
          
        }

        .secTitle {
          margin-top: 26px;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 28px;
          color: rgba(0, 0, 0, 1);
        }
      }

      .example {
        display: flex;
        margin-top: 20px;

        .example-left {
          width: 300px;

          .example-item {
            margin-top: 27px;
            display: flex;
            align-items: center;

            .pic {
              .img {
                width: 20px;
                height: 20px;
              }
            }

            .text {
              margin-left: 16px;
              width: 240px;

              .text-span {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 22.4px;
                color: rgba(68, 68, 68, 1);
              }
            }
          }
        }

        .example-right {

          .example-item {
            display: flex;
            align-items: center;
            margin-top: 27px;

            .pic {
              border-radius: 2px;

              .img {
                width: 20px;
                height: 20px;
              }
            }

            .text {
              margin-left: 16px;
              width: 240px;

              .text-span {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 22.4px;
                color: rgba(68, 68, 68, 1);
              }
            }

            .more {
              display: flex;
              align-items: center;
              margin-left: 2px;

              .circle {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #000;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .container-right {
      position: absolute;
      top: -60px;
      left: 640px;

      .phoneImg {
        width: 460px;
        height: 900px;
      }
    }
  }

  .containerBot {
    display: flex;
    background-color: #444;
    height: 410px;
    padding-bottom: 50px;

    .container {
      width: 1200px;
      margin: auto;
    }

    .container-left {
      .head {
        margin-top: 60px;

        .homeTitle {
          margin-top: 120px;
          font-size: 36px;
          font-weight: 700;
          letter-spacing: 0px;
          color: rgba(255, 255, 255, 1);
          background: linear-gradient(to top, #fdd000 2px, #f8f8f8 40%, #f8f8f8 51%, #f8f8f8 100%);

        }

        .secTitle {
          margin-top: 26px;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 28px;
          color: rgba(255, 255, 255, 1);
        }
      }

      .example {
        display: flex;
        margin-top: 20px;

        .example-left {
          width: 300px;

          .example-item {
            margin-top: 27px;
            display: flex;
            align-items: center;

            .pic {
              .img {
                width: 20px;
                height: 20px;
              }
            }

            .text {
              margin-left: 16px;
              width: 240px;

              .text-span {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 22.4px;
                color: rgba(255, 255, 255, 1);
              }
            }
          }
        }

        .example-right {

          .example-item {
            display: flex;
            align-items: center;
            margin-top: 27px;

            .pic {
              border-radius: 2px;

              .img {
                width: 20px;
                height: 20px;
              }
            }

            .text {
              margin-left: 16px;
              width: 240px;

              .text-span {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 22.4px;
                color: rgba(255, 255, 255, 1);
              }
            }
          }
        }
      }
    }
  }


}


.facePartner {
  width: 100%;
  background: #fff;
  padding-bottom: 150px;

  .services {
    position: relative;
    width: 1200px;
    margin: auto;
    padding-top: 120px;

    .background-text {
      display: flex;
      justify-content: space-between;

      .text-item {
        height: 101px;
        font-size: 72px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 100.8px;
        color: #eeeeee;
      }
    }


    .textCenter {
      text-align: center;
      margin-top: -80px;

      .homeTitle {
        position: relative;
        width: 400px;
        margin: auto;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 32px;
        color: rgba(0, 0, 0, 1);
        background: linear-gradient(to top, #fdd000 4px, #f8f8f8 50%, #f8f8f8 51%, #f8f8f8 100%);

      }

      .title-span {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
      }
    }

    .topBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1200px;
      margin-top: 55px;
      height: 698px;
      background: #444444;
      border-radius: 20px;
      box-shadow: 10px 10px 10px #bababa;
      position: relative;
      z-index: 99;

      .img {
        width: 1160px;
        height: 658px;
      }
    }

    .row {
      position: absolute;
      right: -70px;
      top: 900px;


      .rowImg {
        width: 140px;
        height: 140px;

      }
    }

    .fotBox {
      margin-top: 100px;

      .InBox {
        display: flex;
        justify-content: flex-start;
        width: 1200px;
        margin: auto;
        margin-top: 40px;

        .inUl {
          display: flex;
          justify-content: flex-start;

          .card-item {
            display: flex;
            width: 600px;

            .pic {
              margin-right: 40px;
              border-radius: 10px;
              width: 100px;
              height: 100px;
              z-index: 999;

              .img {
                width: 100px;
                height: 100px;
                border-radius: 10px;

              }
            }

            .text {
              margin-top: 15px;

              .title {
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 0px;
                line-height: 28px;
                color: rgba(0, 0, 0, 1);

              }

              .written {
                margin-top: 10px;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 22.4px;
                color: rgba(68, 68, 68, 1);

              }
            }
          }
        }
      }


    }
  }
}

.freeService {
  width: 100%;
  background: #f8f8f8;
  padding-bottom: 150px;

  .services {
    width: 1200px;
    margin: auto;
    padding-top: 120px;

    .background-text {
      display: flex;
      justify-content: space-between;

      .text-item {
        height: 101px;
        font-size: 72px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 100.8px;
        color: #eeeeee;
      }
    }


    .textCenter {
      text-align: center;
      margin-top: -80px;

      .homeTitle {
        position: relative;
        width: 330px;
        margin: auto;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 32px;
        color: rgba(0, 0, 0, 1);
        background: linear-gradient(to top, #fdd000 4px, #f8f8f8 50%, #f8f8f8 51%, #f8f8f8 100%);

      }

      .title-span {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
      }
    }

    .customersType {
      margin-top: 50px;

      .rudiment {
        .top {
          display: flex;
          align-items: center;

          .customersTypeIcon {
            width: 38px;
            height: 36px;
            margin-right: 16px;
          }

          .title {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 33.6px;
            color: rgba(0, 0, 0, 1);
          }
        }

        .serviceType {
          display: flex;
         margin-top: 30px;

          .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 80px;

            .serviceIcon {
              width: 80px;
              height: 80px;
            }

            .serviceTitle {
              margin-top: 10px;
              width: 80px;
              font-size: 20px;
              font-weight: 700;
              letter-spacing: 0px;
              line-height: 28px;
              color: rgba(0, 0, 0, 1);
            }
          }

          .right {
            width: 1080px;
            margin-top: 30px;

            .items {
              display: flex;
              justify-content: space-between;

              .item {

                .itemTitle {
                  font-size: 16px;
                  font-weight: 700;
                  letter-spacing: 0px;
                  line-height: 28px;
                  color: rgba(0, 0, 0, 1);
                }

                .itemDetail {
                  margin-top: 10px;
                  font-size: 16px;
                  font-weight: 400;
                  letter-spacing: 0px;
                  line-height: 24px;
                  color: rgba(68, 68, 68, 1);
                }
              }

            }

            .hr {
              margin-top: 20px;
              width: 100%;
              height: 2px;
              background: linear-gradient(to right, transparent 0%, transparent 50%,#ccc 50%,#ccc 100%);
              background-size: 15px 1px;
              background-repeat: repeat-x;

            }
          }
        }
      }

      .senior {
        .top {
          display: flex;
          align-items: center;
          margin-top: 80px;
          margin-bottom: 50px;

          .customersTypeIcon {
            width: 38px;
            height: 36px;
            margin-right: 16px;
          }

          .title {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 33.6px;
            color: rgba(0, 0, 0, 1);
          }
        }

        .containerDetail {
          display: flex;
         
          .leftImg {
            width: 800px;
            height: 460px;
            border-radius: 16px;
            background: rgba(68, 68, 68, 1);
            box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
            margin-right: 50px;
            padding: 12px;

            .img {
              width: 776px;
              height: 436px;
            }
          }

          .rightArticle {
            .articleTitle {
              font-size: 16px;
              font-weight: 700;
              letter-spacing: 0px;
              line-height: 22.4px;
              color: rgba(0, 0, 0, 1);
            }
            .articleDetail{
              width:350px;
              margin-top: 16px;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 24px;
              color: rgba(68, 68, 68, 1);
              margin-bottom: 30px;
            }
          }
        }
      }

    }
  }
}

.commercial-college {
  margin: auto;
  margin-top: 120px;
  margin-bottom: 250px;
  width: 1200px;
  display: flex;

  .about-left {
    width: 600px;

    .pic {
      display: flex;
      position: relative;

      .pic-left {
        z-index: 999;

        .logistics {
          margin-top: 90px;
          width: 270px;
          height: 320px;
          border-radius: 20px;
          background: rgba(255, 224, 189, 1);
          padding: 40px 30px;
          position: relative;
          cursor: pointer;

          .topTitle {
            .title {
              width: 96px;
              height: 34px;
              font-size: 24px;
              font-weight: 700;
              letter-spacing: 0px;
              line-height: 33.6px;
              color: rgba(0, 0, 0, 1);
            }
          }

          .container {
            margin-top: 20px;

            .p {
              width: 144px;
              height: 26px;
              opacity: 1;
              font-size: 18px;
              line-height: 25.2px;
              color: rgba(68, 68, 68, 1);
              margin-bottom: 8px;
            }
          }

          .pic {
            position: absolute;
            right: 40px;
            bottom: 40px;

            .img {
              width: 60px;
              height: 60px;
            }
          }
        }

        .logistics:hover {
          box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
          transform: translate(1s);
        }
      }

      .pic-right {
        margin-left: 40px;
        z-index: 999;

        .top {
          width: 222px;
          height: 203px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(209, 236, 255, 1);
          padding: 30px 30px;
          position: relative;
          cursor: pointer;

          .topTitle {
            .title {
              height: 34px;
              font-size: 24px;
              font-weight: 700;
              letter-spacing: 0px;
              line-height: 33.6px;
              color: rgba(0, 0, 0, 1);
            }
          }

          .container {
            margin-top: 20px;

            .p {
              width: 144px;
              height: 26px;
              opacity: 1;
              font-size: 18px;
              line-height: 25.2px;
              color: rgba(68, 68, 68, 1);
              margin-bottom: 8px;
            }
          }

          .pic {
            position: absolute;
            right: 20px;
            bottom: 24px;

            .img {
              width: 48px;
              height: 48px;
            }
          }
        }

        .top:hover {
          box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
          transform: translate(1s);
        }

        .bot {
          margin-top: 40px;
          width: 270px;
          height: 352px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(233, 212, 255, 1);
          padding: 40px 30px;
          cursor: pointer;
          position: relative;

          .topTitle {
            .title {
              width: 96px;
              height: 34px;
              font-size: 24px;
              font-weight: 700;
              letter-spacing: 0px;
              line-height: 33.6px;
              color: rgba(0, 0, 0, 1);
            }
          }

          .container {
            margin-top: 20px;

            .p {
              height: 26px;
              opacity: 1;
              font-size: 18px;
              line-height: 25.2px;
              color: rgba(68, 68, 68, 1);
              margin-bottom: 8px;
            }
          }

          .pic {
            position: absolute;
            right: 40px;
            bottom: 40px;

            .img {
              width: 70px;
              height: 74px;
            }
          }
        }

        .bot:hover {
          box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
          transform: translate(1s);
        }
      }

      .rowImg {
        position: absolute;
        bottom: 50px;
        left: 135px;
        width: 178px;
        height: 178px;
        z-index: 1;
      }

      .rowImg2 {
        position: absolute;
        top: 100px;
        left: 440px;
        width: 178px;
        height: 178px;
        z-index: 10;
      }
    }



  }

  .about-right {
    width: 600px;

    .head {
      margin-top: 140px;
      margin-left: 80px;

      .homeTitle {
        display: inline-block;
        height: 23px;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 15px;
        color: rgba(0, 0, 0, 1);
        background: linear-gradient(to top, #fdd000 10px, #f8f8f8 40%, #f8f8f8 51%, #f8f8f8 100%);
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: -10px;

      }
    }

    .small-text {
      margin-top: 30px;
      margin-left: 60px;

      .small {
        width: 158px;
        height: 28px;
        opacity: 1;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 28px;
        color: rgba(0, 0, 0, 1);
      }
    }

    .container {
      margin-top: 10px;
      margin-left: 70px;
      height: 192px;

      .item {
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 2px;
        line-height: 8px;
        color: rgba(68, 68, 68, 1);

      }
    }
  }

}
.choiceService {
  width: 100%;
  background: #f8f8f8;
  padding-bottom: 150px;

  .services {
    width: 1200px;
    margin: auto;
    padding-top: 120px;

    .background-text {
      display: flex;
      justify-content: space-between;

      .text-item {
        height: 101px;
        font-size: 72px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 100.8px;
        color: #eeeeee;
      }
    }


    .textCenter {
      text-align: center;
      margin-top: -80px;

      .homeTitle {
        position: relative;
        width: 400px;
        margin: auto;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 32px;
        color: rgba(0, 0, 0, 1);
        background: linear-gradient(to top, #fdd000 4px, #f8f8f8 50%, #f8f8f8 51%, #f8f8f8 100%);

      }

      .title-span {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
      }
    }
    .priceImg {
      display: flex;
      justify-content: center;
       margin-top:80px ;
      .price {
      width: 1200px;
      height: 888px;
      margin-bottom: 50px;
      }
    }
    .all {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .titleTop {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 28px;
        color: rgba(0, 0, 0, 1);
      }
      .contactImg {
        width: 200px;
        height: 200px;
        margin-bottom: 10px;
      }
      .titleBot {
        margin-top: 6px;
        width: 352px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(0, 0, 0, 1);
        text-align: center;
      }
    }
  }
}
</style>
